import { CheckCircleOutlined, CloseCircleOutlined, EyeOutlined, FileSyncOutlined, RetweetOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, DatePicker as DatePickerAnt, Input as InputAnt, Modal, Select as SelectAnt, Table, UploadFile, UploadProps } from 'antd';
import locale from 'antd/es/date-picker/locale/tr_TR';
import React, { useCallback, useContext, useState } from 'react';

import { z } from 'zod';
import * as API from '../../api';
import { DatePicker, DocViewer, DraggableModal, Input, Select, Toolbar, ToolbarLeft, ToolbarRight } from '../../components';
import { UserContext } from '../../contexts/UserContext';
import { useFillForm, useOrderList, useWindowSize } from '../../hooks';
import { EVET_HAYIR, ROL, SiparisModel } from '../../types';
import Upload from 'antd/es/upload/Upload';

const RedForm = z.object({
  redAciklama: z.string().optional(),
});

const OnayForm = z.object({
  faturaTarihi: z.date({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  alisFaturaNo: z.string().nullish(),
  efaturaMi: z.string({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
});

const EFaturaOnayForm = z.object({
  faturaTarihi: z.date({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
});

export const BillStep2 = () => {
  const kullaniciModel = useContext(UserContext);
  const { sm } = useWindowSize();

  const [markets = []] = API.MARKETS.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [categories = []] = API.CATEGORY.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [brands = []] = API.BRAND.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [products = []] = API.PRODUCT.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [warehouses = []] = API.WAREHOUSES.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOnayModalOpen, setIsOnayModalOpen] = useState(false);
  const [isEFaturaOnayModalOpen, setIsEFaturaOnayModalOpen] = useState(false);
  const [file, setFile] = useState<UploadFile>();

  const { form, clear, fill } = useFillForm(RedForm, {
    redAciklama: '',
  });

  const onayForm = useFillForm(OnayForm, {
    faturaTarihi: new Date(),
    alisFaturaNo: ' ',
    efaturaMi: 'H',
  });

  const eFaturaOnayForm = useFillForm(EFaturaOnayForm, {
    faturaTarihi: new Date(),
  });

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showOnayModal = () => {
    if (selectedList?.[0].faturaTarihi) {
      onayForm.fill({ faturaTarihi: new Date(selectedList[0].faturaTarihi), efaturaMi: 'H' });
    }
    setIsOnayModalOpen(true);
  };

  const showEFaturaOnayModal = () => {
    if (selectedList?.[0].faturaTarihi) {
      eFaturaOnayForm.fill({ faturaTarihi: new Date(selectedList[0].faturaTarihi) });
    }
    setIsEFaturaOnayModalOpen(true);
  };

  const {
    users = [],
    list = [],
    isAdmin,
    setSelectedUserId,
    loading,
    getList,
    columns,
    selectedKeyList,
    selectedList,
    onMultiSelectClick,
    isPdf,
    doc,
    byteArray,
    isDocModalOpen,
    setIsDocModalOpen,
    getDoc,
    depoyeri,
    setDepoyeri,
    pazaryeri,
    setPazaryeri,
    kategori,
    setKategori,
    marka,
    setMarka,
    urun,
    setUrun,
    siparisNo,
    setSiparisNo,
    siparisId,
    setSiparisId,
    siparisTarihi,
    setSiparisTarihi,
  } = useOrderList(false, true);

  const handleCancel = useCallback(() => {
    clear();
    setIsDocModalOpen(false);
    setIsModalOpen(false);
    setIsOnayModalOpen(false);
    setIsEFaturaOnayModalOpen(false);
  }, [clear, setIsDocModalOpen, setIsModalOpen, setIsOnayModalOpen, setIsEFaturaOnayModalOpen]);

  const approve = useCallback(async () => {
    if (selectedList) {
      const { success, data, error } = onayForm.form.parse();
      if (success) {
        const siparisId = Number(selectedList[0].siparisId);

        const date = new Date(data.faturaTarihi);
        date.setDate(15);
        date.setHours(10);
        date.setMinutes(10);
        date.setSeconds(10);
        date.setMilliseconds(10);

        console.log(data, date.toISOString(), data.alisFaturaNo, data.efaturaMi);
        await API.FILES.approve(siparisId, date.toISOString(), data.alisFaturaNo || '', data.efaturaMi);

        handleCancel();
        getList();
      }
    }
  }, [selectedList, onayForm.form]);

  const approveEFatura = useCallback(async () => {
    if (selectedList) {
      const { success, data, error } = eFaturaOnayForm.form.parse();
      if (file && success) {
        const siparisIdList = selectedList.map((order) => Number(order.siparisId));

        const date = new Date(data.faturaTarihi);
        date.setDate(15);
        date.setHours(10);
        date.setMinutes(10);
        date.setSeconds(10);
        date.setMilliseconds(10);

        await API.FILES.approveEfatura(file, siparisIdList, date);

        handleCancel();
        getList();
      }
    }
  }, [selectedList, onayForm.form, eFaturaOnayForm.form]);

  const reject = useCallback(async () => {
    if (selectedList) {
      const { success, data } = form.parse();
      const siparisIdList = selectedList.map((order) => Number(order.siparisId));

      await API.FILES.reject(siparisIdList, data.redAciklama);

      handleCancel();
      getList();
    }
  }, [selectedList, form]);

  const uploadProps: UploadProps = {
    accept: 'image/*,.pdf',
    onRemove: () => {
      setFile(undefined);
    },
    beforeUpload: (file) => {
      setFile(file);

      return false;
    },
    fileList: file ? [file] : [],
  };

  const selectedKeyListSt = (selectedKeyList || []) as React.Key[];

  const productByCategory = products.filter((product) => product.bhrKategoriModel?.kategoriId === kategori && product.bhrMarkaModel?.markaId === marka);

  return (
    <div className="flex flex-col gap-2">
      <Toolbar>
        <ToolbarLeft>
          <Button onClick={getList} icon={<RetweetOutlined />}>
            Yenile
          </Button>
          {kullaniciModel.kullaniciTipi === ROL.ADMIN && (
            <SelectAnt
              className="w-full sm:w-64 md:max-w-[300px]"
              showSearch
              placeholder="Tedarikçi Seçiniz"
              options={users.map((user) => ({ ...user, label: `${user.kullaniciAdi} - ${user.ad} ${user.soyad}` }))}
              fieldNames={{ label: 'label', value: 'kullaniciId' }}
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              allowClear
              onChange={setSelectedUserId}
            ></SelectAnt>
          )}
          <SelectAnt
            className="w-full sm:w-64 md:max-w-[200px]"
            showSearch
            placeholder="Depoyeri"
            options={warehouses}
            fieldNames={{ label: 'depoyeriAdi', value: 'depoyeriId' }}
            filterOption={(input, option) => (option?.depoyeriAdi ?? '').toLowerCase().includes(input.toLowerCase())}
            value={depoyeri}
            onChange={setDepoyeri}
            allowClear
          ></SelectAnt>
        </ToolbarLeft>
        <ToolbarRight classname="sm:flex-none">
          <Button
            className="w-full"
            type="primary"
            disabled={!(selectedList && selectedList.length > 0 && selectedList.every((item) => item.efaturaMi === EVET_HAYIR.EVET))}
            onClick={showEFaturaOnayModal}
            icon={<CheckCircleOutlined />}
          >
            E-Fatura Onayla
          </Button>
          <Button
            className="w-full"
            type="primary"
            disabled={!(selectedList && selectedList.length === 1 && selectedList.every((item) => item.efaturaMi === EVET_HAYIR.HAYIR))}
            onClick={showOnayModal}
            icon={<CheckCircleOutlined />}
          >
            Onayla
          </Button>
          <Button className="w-full" type="primary" danger disabled={!(selectedList && selectedList.length)} onClick={showModal} icon={<CloseCircleOutlined />}>
            Reddet
          </Button>
          <Button className="w-full" disabled={!(selectedList && selectedList.length === 1)} onClick={getDoc} icon={<EyeOutlined />}>
            Görüntüle
          </Button>
        </ToolbarRight>
      </Toolbar>
      <Toolbar>
        <ToolbarLeft classname="sm:flex-none">
          <SelectAnt
            className="w-full sm:w-64 md:max-w-[200px]"
            showSearch
            placeholder="Pazaryeri"
            options={markets}
            fieldNames={{ label: 'pazaryeriAdi', value: 'pazaryeriId' }}
            filterOption={(input, option) => (option?.pazaryeriAdi ?? '').toLowerCase().includes(input.toLowerCase())}
            value={pazaryeri}
            onChange={setPazaryeri}
            allowClear
          ></SelectAnt>
          <SelectAnt
            className="w-full sm:w-64 md:max-w-[200px]"
            showSearch
            placeholder="Kategori"
            options={categories}
            fieldNames={{ label: 'kategoriAdi', value: 'kategoriId' }}
            filterOption={(input, option) => (option?.kategoriAdi ?? '').toLowerCase().includes(input.toLowerCase())}
            value={kategori}
            onChange={setKategori}
            allowClear
          ></SelectAnt>
          <SelectAnt
            className="w-full sm:w-64 md:max-w-[200px]"
            showSearch
            placeholder="Marka"
            options={brands}
            fieldNames={{ label: 'markaAdi', value: 'markaId' }}
            filterOption={(input, option) => (option?.markaAdi ?? '').toLowerCase().includes(input.toLowerCase())}
            value={marka}
            onChange={setMarka}
            allowClear
          ></SelectAnt>
          <SelectAnt
            className="w-full sm:w-64 md:max-w-[200px]"
            showSearch
            placeholder="Ürün"
            options={productByCategory}
            fieldNames={{ label: 'urunAdi', value: 'urunId' }}
            filterOption={(input, option) => (option?.urunAdi ?? '').toLowerCase().includes(input.toLowerCase())}
            value={urun}
            onChange={setUrun}
            allowClear
          ></SelectAnt>
          <InputAnt className="w-full sm:w-64 md:max-w-[200px]" placeholder="Sipariş No" value={siparisNo} onChange={(e) => setSiparisNo(e.target.value)} allowClear></InputAnt>
          <InputAnt
            className="w-full sm:w-64 md:max-w-[120px]"
            placeholder="Kayıt No"
            value={siparisId}
            onChange={(e) => setSiparisId(e.target.value as unknown as number)}
            allowClear
          ></InputAnt>

          <DatePickerAnt.RangePicker
            placeholder={['Sipariş Tarihi Baş.', 'Bitiş Tarihi']}
            value={siparisTarihi}
            onChange={(value) => setSiparisTarihi(value as any)}
            allowClear={true}
            className="w-full sm:w-80"
            locale={locale}
            format="DD.MM.YYYY"
          />
        </ToolbarLeft>
      </Toolbar>
      <Table
        scroll={{ y: sm ? 'calc(100svh - 240px)' : 'calc(100vh - 340px)' }}
        pagination={{
          defaultPageSize: 25,
          pageSizeOptions: [10, 25, 50],
          position: ['topRight'],
          showTotal: (total) => <div>Toplam Kayıt : {total}</div>,
        }}
        rowKey="siparisId"
        size="small"
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={list}
        loading={loading}
        onRow={(record) => ({
          onClick: () => {
            if (isAdmin) {
              const key = record.siparisId as React.Key;
              if (selectedKeyListSt.includes(key)) {
                onMultiSelectClick(selectedKeyListSt.filter((_key) => _key !== key));
              } else {
                onMultiSelectClick([...selectedKeyListSt, key]);
              }
            }
          },
        })}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selectedKeyList || [],
          onChange: onMultiSelectClick,
        }}
      />
      {doc && (
        <Modal
          key={doc.dokumanId}
          width={'min-content'}
          footer={null}
          cancelText="Kapat"
          open={isDocModalOpen}
          onCancel={handleCancel}
          style={{ top: 40 }}
          bodyStyle={{ marginTop: 20, height: '86vh', overflow: 'auto' }}
        >
          {isPdf && <DocViewer doc={doc} byteArray={byteArray} />}
          {!isPdf && <img src={`data:image;base64,${byteArray}`}></img>}
        </Modal>
      )}

      <DraggableModal width={300} title="Fatura Reddetme" okText="Reddet" cancelText="Kapat" okType="danger" open={isModalOpen} onOk={reject} onCancel={handleCancel}>
        <div className="flex flex-col">
          <label className="whitespace-nowrap">Red Açıklama :</label>
          <Input form={form} name="redAciklama" />
        </div>
      </DraggableModal>

      <DraggableModal width={300} title="Fatura Onaylama" okText="Onayla" cancelText="Kapat" open={isOnayModalOpen} onOk={approve} onCancel={handleCancel}>
        <div className="flex flex-col">
          <label className="whitespace-nowrap mt-2">Fatura Tarih :</label>
          <DatePicker picker="month" format="YYYY MMMM" form={onayForm.form} name="faturaTarihi" />

          <label className="whitespace-nowrap">Fatura No :</label>
          <Input form={onayForm.form} name="alisFaturaNo" />

          <label className="whitespace-nowrap">E-Fatura Mı? :</label>
          <Select
            allowClear={false}
            options={[
              { value: EVET_HAYIR.EVET, label: 'Evet' },
              { value: EVET_HAYIR.HAYIR, label: 'Hayır' },
            ]}
            fieldNames={{ label: 'label', value: 'value' }}
            form={onayForm.form}
            name="efaturaMi"
          />
        </div>
      </DraggableModal>

      <DraggableModal width={300} title="E-Fatura Onaylama" okText="Onayla" cancelText="Kapat" open={isEFaturaOnayModalOpen} onOk={approveEFatura} onCancel={handleCancel}>
        <div className="flex flex-col">
          <label className="whitespace-nowrap mt-2">Fatura Tarih :</label>
          <DatePicker picker="month" format="YYYY MMMM" form={eFaturaOnayForm.form} name="faturaTarihi" />

          <Upload {...uploadProps} className="mt-4">
            <Button block icon={<UploadOutlined />}>
              Fatura Seç
            </Button>
          </Upload>
        </div>
      </DraggableModal>
    </div>
  );
};

BillStep2.path = 'bill-step-2';
BillStep2.title = 'Onay Bekleyenler';
BillStep2.group = 'bills';
BillStep2.roles = [ROL.ADMIN, ROL.DEPOCU];
BillStep2.icon = <FileSyncOutlined />;
